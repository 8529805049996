import React from 'react';
import classnames from 'classnames';
import './index.scss';

class Balloon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };

    this.toggleView = this.toggleView.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    const { getStateMgr } = this.props;
    if (document) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }
    if (typeof getStateMgr === 'function') {
      getStateMgr({
        hide: () => {
          this.setState({ hidden: true });
        },
        show: () => {
          this.setState({ hidden: false });
        },
      });
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  }

  toggleView() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleOutsideClick(event) {
    if (this._el.contains(event.target)) {
      return;
    }
    this.setState({ hidden: true });
  }

  render() {
    const { className, children, trigger } = this.props;
    const { hidden } = this.state;
    return (
      <span
        ref={(el) => {
          this._el = el;
        }}
        className={classnames('balloon', className, { 'balloon--hidden': hidden })}
      >
        {!!trigger && (
          <span onClick={this.toggleView} className="balloon__trigger">
            {trigger}
          </span>
        )}
        <div className="balloon__wrapper">{children}</div>
      </span>
    );
  }
}

export default Balloon;
