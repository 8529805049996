import { intl } from '@alife/lazada-im-core';

let locale = {};

const LangMap = {
  en: 'en-us',
  id: 'in-id',
  my: 'ms-my',
  th: 'th-th',
  vi: 'vi-vn',
};

function initLocale(lang) {
  const langKey = LangMap[lang] || 'en-us';
  intl.setLang(langKey);
  locale = intl.getAllLocales(langKey);
}

function getByPrefix(prefix) {
  const res = {};
  Object.keys(locale).forEach((key) => {
    if (key.includes(prefix)) {
      res[key.replace(`${prefix}.`, '')] = locale[key];
    }
  });
  return res;
}

export { initLocale, getByPrefix };
const translate = (key, fallbackText) => locale[key] || fallbackText || '';
export default translate;
