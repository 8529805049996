import React from "react";
export const IconMessage = (props) => <svg width="18px" height="18px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" { ...props } >

    <defs>
        <polygon id="path-1" points="19 19 0 19 0 3.37241379e-15 19 0 19 19"></polygon>
    </defs>
    <g id="header-bar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-21.000000, -15.000000)">
        <g id="lt-col" transform="translate(20.000000, 14.000000)">
            <g id="icon-messages-copy-2" transform="translate(0.000000, 1.000000)">
                <path d="M19.0431655,3.80455635 C19.1770084,3.80455635 19.2994604,3.83018585 19.4105216,3.88239408 C19.5215827,3.93460232 19.621253,4.00484612 19.7104816,4.09407474 C19.7997102,4.16811551 19.869954,4.26398881 19.9221623,4.38264388 C19.9734213,4.50129896 20,4.62754796 20,4.76139089 L20,19 L16.1954436,15.1954436 L5.76139089,15.1954436 C5.62754796,15.1954436 5.50129896,15.1698141 5.38264388,15.1176059 C5.26398881,15.0653977 5.16811551,14.9951539 5.09407474,14.9059253 C5.00484612,14.8318845 4.93460232,14.7360112 4.88239408,14.6173561 C4.83018585,14.498701 4.80455635,14.372452 4.80455635,14.2386091 L4.80455635,12.3477218 L17.1522782,12.3477218 L17.1522782,3.80455635 L19.0431655,3.80455635 Z M15.2386091,9.5 C15.2386091,9.63384293 15.2167766,9.75629496 15.1721623,9.86735612 C15.127548,9.97841727 15.0611011,10.0780875 14.9718725,10.1673161 C14.8826439,10.2565448 14.7791767,10.3267886 14.6605216,10.3789968 C14.5418665,10.4302558 14.4232114,10.4568345 14.3045564,10.4568345 L4.80455635,10.4568345 L1,14.2386091 L1,0.956834532 C1,0.822991607 1.02657874,0.700539568 1.07783773,0.589478417 C1.13004596,0.478417266 1.20028977,0.378747002 1.28951839,0.289518385 C1.378747,0.200289768 1.47841727,0.130045963 1.58947842,0.0778377298 C1.70053957,0.026578737 1.82299161,0 1.95683453,0 L14.3045564,0 C14.4232114,0 14.5418665,0.026578737 14.6605216,0.0778377298 C14.7791767,0.130045963 14.8826439,0.200289768 14.9718725,0.289518385 C15.0611011,0.378747002 15.127548,0.478417266 15.1721623,0.589478417 C15.2167766,0.700539568 15.2386091,0.822991607 15.2386091,0.956834532 L15.2386091,9.5 Z" id="Fill-1" fill="#9E9E9E" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
</svg>