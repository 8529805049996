const MAX_FILE_SIZE = 1024 * 1024 * 10;

export default files =>
  new Promise((resolve, reject) => {
    if (window.FileReader) {
      const targetFile = files[0];
      const Reader = new FileReader();
      Reader.addEventListener('load', () => resolve(Reader.result), false);

      if (targetFile.size < MAX_FILE_SIZE) {
        Reader.readAsDataURL(targetFile);
      } else {
        reject('File size too large');
      }
    } else {
      reject('window.FileReader is not supported');
    }
  });
