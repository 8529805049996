export const I18nMsg = {
  "vi": {
    "im.common.windowTitle": "Tin nhắn",
    "im.message.failedStatus": "Gửi tin nhắn không thành công, {resend}. Vui lòng thử lại, {delete}.",
    "im.messageView.emptyNotice": "Khi bạn bắt đầu một cuộc trò chuyện mới, cuộc trò chuyện sẽ được liệt kê ở đây.",
    "im.message.downloadAppLoggedOut": "Dùng Ứng dụng Lazada để nhận vô vàn ưu đãi độc quyền",
    "im.messageView.placeholder": "Nhập tin nhắn của bạn…",
    "im.messageView.noInternet": "Không có kết nối Internet",
    "im.smart-card.buttonCancelText": "Hủy",
    "im.windowConfirm.title": "Xác nhận",
    "im.imagePreview.cancel": "Hủy",
    "im.message.signUp": "ĐĂNG KÝ",
    "im.message.unread": "Chưa đọc",
    "im.sesstionItem.draft": "NHÁP",
    "im.windowConfirm.message": "Bạn có chắc rằng bạn muốn xoá tập tin này? Bạn không thể hoàn tác sau khi xoá.",
    "im.message.resend": "Gửi lại",
    "im.windowConfirm.ok": "OK",
    "im.formattedDate.yesterday": "hôm qua",
    "im.message.read": "Đã đọc",
    "im.message.login": "Đăng nhập",
    "im.imagePreview.send": "Gửi",
    "im.message.delete": "Xóa",
    "im.windowConfirm.cancel": "Hủy",
    "im.smart-card.sendProduct": "Gửi Sản Phẩm",
    "im.message.downloadAppLoggedIn": "Dùng Ứng dụng Lazada trải nghiệm Trò chuyện tốt nhất"
  },
  "en": {
    "im.common.windowTitle": "Messages",
    "im.message.failedStatus": "Message failed to send. {resend} or {delete}",
    "im.messageView.emptyNotice": "Once you start a new conversation, you’ll see it listed here.",
    "im.message.downloadAppLoggedOut": "Use the Lazada App for exclusive offers!",
    "im.messageView.placeholder": "Enter your message",
    "im.messageView.noInternet": "No Internet Connection",
    "im.smart-card.buttonCancelText": "Cancel",
    "im.windowConfirm.title": "Confirm",
    "im.imagePreview.cancel": "Cancel",
    "im.message.signUp": "Sign up",
    "im.message.unread": "Unread",
    "im.sesstionItem.draft": "DRAFT",
    "im.windowConfirm.message": "Are you sure you want to delete?",
    "im.message.resend": "Resend",
    "im.windowConfirm.ok": "Ok",
    "im.formattedDate.yesterday": "Yesterday",
    "im.message.read": "Read",
    "im.message.login": "Login",
    "im.imagePreview.send": "Send",
    "im.message.delete": "Delete",
    "im.windowConfirm.cancel": "Cancel",
    "im.smart-card.sendProduct": "Send Product",
    "im.message.downloadAppLoggedIn": "Use the App for the best Chat experience!"
  },
  "id": {
    "im.common.windowTitle": "Pesan",
    "im.message.failedStatus": "Gagal mengirim pesan. silakan {resend} atau {delete}.",
    "im.messageView.emptyNotice": "Setelah memulai percakapan baru, Anda akan melihatnya di sini.",
    "im.message.downloadAppLoggedOut": "Gunakan aplikasi Lazada untuk penawaran eksklusif",
    "im.messageView.placeholder": "Tulis pesan Anda…",
    "im.messageView.noInternet": "Tidak Ada Koneksi Internet",
    "im.smart-card.buttonCancelText": "Batal",
    "im.windowConfirm.title": "Konfirmasi",
    "im.imagePreview.cancel": "Batal",
    "im.message.signUp": "DAFTAR",
    "im.message.unread": "Belum Dibaca",
    "im.sesstionItem.draft": "DRAF",
    "im.windowConfirm.message": "Anda yakin menghapus file ini? Tindakan ini tidak dapat dibatalkan.",
    "im.message.resend": "Kirim ulang",
    "im.windowConfirm.ok": "OK",
    "im.formattedDate.yesterday": "kemari",
    "im.message.read": "Dibaca",
    "im.message.login": "Login",
    "im.imagePreview.send": "Kirim",
    "im.message.delete": "Hapus",
    "im.windowConfirm.cancel": "Batal",
    "im.smart-card.sendProduct": "Mengirim Produk",
    "im.message.downloadAppLoggedIn": "Buka Chat via aplikasi untuk pengalaman terbaik"
  },
  "th": {
    "im.common.windowTitle": "ข้อความ",
    "im.message.failedStatus": "ไม่สามารถส่งข้อความได้ {resend} หรือ {delete}",
    "im.messageView.emptyNotice": "เมื่อคุณเริ่มการสนทนาใหม่ คุณจะเห็นรายการสนทนาใหม่ที่นี่",
    "im.message.downloadAppLoggedOut": "ใช้แอพพลิเคชันลาซาด้าสำหรับสิทธิพิเศษมากมาย",
    "im.messageView.placeholder": "พิมพ์ข้อความของคุณ…",
    "im.messageView.noInternet": "ไม่มีการเชื่อมต่ออินเทอร์เน็ต",
    "im.smart-card.buttonCancelText": "ยกเลิก",
    "im.windowConfirm.title": "ยืนยัน",
    "im.imagePreview.cancel": "ยกเลิก",
    "im.message.signUp": "สมัครสมาชิก",
    "im.message.unread": "ยังไม่อ่าน",
    "im.sesstionItem.draft": "ร่าง",
    "im.windowConfirm.message": "คุณแน่ใจจะลบหรือไม่ คุณไม่สามารถแก้ไขคำสั่งนี้ได้",
    "im.message.resend": "ส่งใหม่อีกครั้ง",
    "im.windowConfirm.ok": "OK",
    "im.formattedDate.yesterday": "เมื่อวานนี้",
    "im.message.read": "อ่าน",
    "im.message.login": "เข้าสู่ระบบ",
    "im.imagePreview.send": "ส่ง",
    "im.message.delete": "ลบ",
    "im.windowConfirm.cancel": "ยกเลิก",
    "im.smart-card.sendProduct": "ส่งผลิตภัณฑ์",
    "im.message.downloadAppLoggedIn": "ใช้แอพพลิเคชั่นเพื่อแชทกับเราได้ดีขึ้น"
  },
  "en-SG": {
    "im.common.windowTitle": "Messages",
    "im.message.failedStatus": "Message failed to send. {resend} or {delete}",
    "im.messageView.emptyNotice": "Once you start a new conversation, you’ll see it listed here.",
    "im.message.downloadAppLoggedOut": "Use the Lazada App for exclusive offers!",
    "im.messageView.placeholder": "Enter your message",
    "im.messageView.noInternet": "No Internet Connection",
    "im.smart-card.buttonCancelText": "Cancel",
    "im.windowConfirm.title": "Confirm",
    "im.imagePreview.cancel": "Cancel",
    "im.message.signUp": "Sign up",
    "im.message.unread": "Unread",
    "im.sesstionItem.draft": "DRAF",
    "im.windowConfirm.message": "Are you sure you want to delete?",
    "im.message.resend": "Resend",
    "im.windowConfirm.ok": "OK",
    "im.formattedDate.yesterday": "Yesterday",
    "im.message.read": "Read",
    "im.message.login": "Login",
    "im.imagePreview.send": "Send",
    "im.message.delete": "Delete",
    "im.windowConfirm.cancel": "Cancel",
    "im.smart-card.sendProduct": "Send Product",
    "im.message.downloadAppLoggedIn": "Use the App for the best Chat experience!"
  }
}
