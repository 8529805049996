const isProd = process.env.NODE_ENV === 'production';

const Env = {
  env: isProd ? 'production' : 'pre',
  appKey: 24814220,
  getUmidToken() {
    const umx = window.umx;
    return umx && umx.getToken();
  },
};

export default Env;
