import React from "react";
export const DeleteIcon = (props) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" { ...props } >
  <defs>
    <filter x="-1.5%" y="-1.9%" width="103.1%" height="103.6%" filterUnits="objectBoundingBox" id="a">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
  </defs>
  <path d="M11.142 3.334H.856v10c0 .92.768 1.666 1.715 1.666h6.857c.948 0 1.714-.747 1.714-1.667V3.334M8.142 0H3.856L3 .834H.856A.845.845 0 0 0 0 1.667V2.5h12v-.834a.846.846 0 0 0-.858-.833H9L8.142 0"
    transform="translate(6 6)" filter="url(#a)" fill="#757575" fill-rule="evenodd" data-spm-anchor-id="a2o42.home.1355784120.i1.654346b5lCj8Rf"></path>
</svg>