import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import bem from '../../utils/bem';

import { IconError} from './error';
import './index.scss';

const icons = {
  error: IconError,
};

const Notification = (props) => {
  const { type, text } = props;
  const BEM = bem('im-notification', props);

  return (
    <div className={BEM.block()}>
      {icons[type] && (
        <div className={BEM.element('icon')}>
          {createElement(icons[type], { width: 18, height: 18 })}
        </div>
      )}
      <p className={BEM.element('text')}>{text}</p>
    </div>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf(['error']),
  text: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  type: 'error',
};

export default Notification;
