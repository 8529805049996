import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from '@alife/universal-intl';
import withI18n from '@ali/lzdmod-intl-utils';
import { intlName } from '../../constants';
import bem from '../../utils/bem';
import MessageViewTitle from '../message-view-title';
import MessageViewContent from '../message-view-content';
import MessageViewField from '../message-view-field';
import Notification from '../../components/notification';
import SmartCard from '../smart-card';
import './index.scss';

const messages = defineMessages({
  noInternet: {
    id: 'im.messageView.noInternet',
    defaultMessage: 'No Internet Connection',
  },
});

const MessageView = (props) => {
  const { onLine = true, isShowViewField, translate } = props;
  const BEM = bem('message-view', props);

  return (
    <div className={BEM.block()}>
      <MessageViewTitle />
      {!onLine && <Notification type="error" text={translate('noInternet')} />}
      <SmartCard />
      <MessageViewContent />
      {isShowViewField && <MessageViewField />}
    </div>
  );
};

MessageView.propTypes = {
  onLine: PropTypes.bool.isRequired,
  isShowViewField: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(messages, intlName)(MessageView);
