import React from 'react';
import flatten from 'lodash/flatten';
import memoize from 'lodash/memoize';
import map from 'lodash/map';

const getEmojiMapMemo = memoize((emojiGroup) => {
  const plainList = flatten(map(emojiGroup, 'list'));
  return plainList.reduce((acc, item) => {
    if (item.type === 'emoji') acc[item.key] = item;
    return acc;
  }, Object.create(null));
});

export const createSymbolReplace = (emojiGroup) => {
  const emojiMap = getEmojiMapMemo(emojiGroup);

  const symbolReplace = (text, index) => {
    // . => [\s\S] fix br
    const segs = text.match(/\[([a-zA-Z0-9-_ ]+)\]|((?!\[(?:[a-zA-Z0-9-_ ]+)\])[\s\S])+/g) || [];
    const result = [];
    let hasText = false; // dingtalk: limit emoji size when has text
    let emojiCount = 0; // dingtalk: limit emoji size when >= 4 emojis
    segs.forEach((seg, i) => {
      if (/\[([a-zA-Z0-9-_ ]+)\]/.test(seg)) {
        const item = emojiMap[seg];
        if (item) {
          emojiCount += 1;
          result.push(<span
            // eslint-disable-next-line react/no-array-index-key
            key={`emoji-img-${index}_${i}`}
            className="emoji-img"
          >
            <span className="hide-for-select">{seg}</span>
            <img src={item.smallImgUrl} />
          </span>);
          return;
        }
      }
      hasText = true;
      result.push(<span>{seg}</span>);
    });
    return [result, hasText, emojiCount];
  };

  return symbolReplace;
};

