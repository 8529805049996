import MessageViewTitle from '../../message-view-title/component';
import SessionToolbar from '../../../components/session-toolbar';
import { IconMailBox } from '../../message-view-content/mailbox';
import withI18n from '@ali/lzdmod-intl-utils';
import { FormattedMessage } from '@alife/intl-components';
import { defineMessages } from '@alife/universal-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { intlName } from '../../../constants';
import { gotoPage, PAGE_INTENT } from '../../../utils/router';
import { siteName, getRegion } from '../../../utils/host';
import Config from '../../../utils/config';
import './index.scss';

const downloadPageAddress = Config.DownloadAppAddress[getRegion().toUpperCase()];

const messages = defineMessages({
  Login: {
    id: 'im.message.login',
    defaultMessage: 'Login',
  },
  'Sign up': {
    id: 'im.message.signUp',
    defaultMessage: 'Sign up',
  },
  downloadAppLoggedOut: {
    id: 'im.message.downloadAppLoggedOut',
    defaultMessage: 'Get the latest merchant response in App Now',
  },
});

const Login = function ({ translate, onMinimize }) {
  return (
    <div className="login">
      <div className={'left-sidebar'}>
        <SessionToolbar />
        <div className="blank" />
      </div>
      <div className="message-view">
        <MessageViewTitle title={siteName} onMinimize={onMinimize} />
        <div className="message-view-content">
          <div className="empty-box">
            <span className="empty-icon">
              <IconMailBox width={55} height={47} />
            </span>
            <div className="donwload-address">
              <a target="_blank" href={downloadPageAddress} rel="noreferrer noopener">
                {translate('downloadAppLoggedOut').replace('Lazada', '')}
              </a>
            </div>
            <FormattedMessage
              intlName={intlName}
              id="im.messageView.emptyNotice"
              defaultMessage="Once you start a new conversation, you’ll see it listed here."
              tagName="p"
            />
          </div>
        </div>
        <div className="login-box">
          <span onClick={() => gotoPage(PAGE_INTENT.login)}>{translate('Login')}</span> /
          <span onClick={() => gotoPage(PAGE_INTENT.register)}>{translate('Sign up')}</span>
        </div>
      </div>
    </div>
  );
};

Login.defaultProps = {
  onMinimize: () => {},
  translate: () => {},
};

Login.propTypes = {
  onMinimize: PropTypes.func,
  translate: PropTypes.func,
};

export default withI18n(messages, intlName)(Login);
