import React from 'react';

export const IconClose = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="header-bar" transform="translate(-623.000000, -17.000000)" fill="#9E9E9E">
        <g id="rt-col" transform="translate(281.000000, 14.000000)">
          <path
            d="M357.854901,18.152233 C358.053053,18.3508419 358.047673,18.6673642 357.84232,18.8593651 C357.637298,19.0513652 357.310934,19.0461476 357.112699,18.8475387 L349.999746,11.7191778 L349.915072,11.7955298 L342.887124,18.8475387 C342.688889,19.0461476 342.362526,19.0513652 342.157503,18.8593651 C341.952481,18.6673642 341.94677,18.3508419 342.145336,18.152233 L349.282376,10.999872 L342.145336,3.8475079 C341.94677,3.64889817 341.952481,3.33237667 342.157503,3.14037416 C342.362526,2.94872447 342.688889,2.95393648 342.887124,3.15220142 L349.999746,10.2805663 L357.112699,3.15220142 C357.310934,2.95393648 357.637298,2.94872447 357.84232,3.14037416 C358.047673,3.33237667 358.053053,3.64889817 357.854901,3.8475079 L350.717862,10.999872 L357.854901,18.152233 Z"
            id="icon-close"
          />
        </g>
      </g>
    </g>
  </svg>
);
