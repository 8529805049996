import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from '@alife/universal-intl';
import { intlName } from '../../constants';
import withI18n from '@ali/lzdmod-intl-utils';
import './index.scss';

const messages = defineMessages({
  send: {
    id: 'im.smart-card.sendProduct',
    defaultMessage: 'Send Product',
  },
  cancel: {
    id: 'im.smart-card.buttonCancelText',
    defaultMessage: 'Cancel',
  },
});

const SmartCard = ({
  translations,
  className,
  productInfo,
  loading,
  onClickSend,
}) => {
  if (!productInfo) {
    return null;
  }
  const image = productInfo.get('pic');
  const title = productInfo.get('title');
  const price = productInfo.get('price');
  const originalPrice = productInfo.get('originalPrice');
  const discountText = productInfo.get('discountText');

  return (
    <div className={`im-smart-card ${className}`}>
      <div className="content">
        <img src={image} alt={title} className="image" />
        <div className="desc">
          <div className="title two-lines" title={title}>{title}</div>
          <div className="price text-overflow" title={price}>{price}</div>
          {originalPrice &&
            <div className="discount-row">
              <div className="original-price text-overflow" title={originalPrice}>{originalPrice}</div>
              <div className="discount-text">{discountText}</div>
            </div>
          }
        </div>
      </div>
      <div className="footer">
        <button disabled={loading} onClick={() => onClickSend()} className="btn">
          {translations.send()}
        </button>
      </div>
    </div>
  );
};

SmartCard.defaultProps = {
  productInfo: null,
  className: '',
  loading: false,
};

SmartCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClickSend: PropTypes.func.isRequired,
  productInfo: PropTypes.shape({}),
  translations: PropTypes.shape({}).isRequired,
};

export default withI18n(messages, intlName)(SmartCard);
