import get from 'lodash.get';

const DownloadAppAddress = {
  TH: 'https://ali.lazada.co.th/wow/i/th/THCampaign/appbenefit?spm=a2o4m.home.header.1.72ac515f7NQNwc&hybrid=1',
  PH: 'https://pages.lazada.com.ph/wow/i/ph/PHCampaign/app-download-page?spm=a2o4l.home.header.1.37bf6ef0x9cl6o&hybrid=1',
  MY: 'https://pages.lazada.com.my/wow/i/my/MYCampaign/download-the-app?spm=a2o4k.home.header.1.75f824f6TZap8e&hybrid=1',
  SG: 'https://pages.lazada.sg/wow/i/sg/SGCampaign/download-the-app/?spm=a2o42.home.header.1.4f1646b5YLrSyx',
  ID: 'https://pages.lazada.co.id/wow/i/id/IDCampaign/Download-App?spm=a2o4j.home.header.1.48ca4cee5K4pST&hybrid=1',
  VN: 'https://www.lazada.vn/wow/i/vn/VNCampaign/uu-dai-tren-lazada-app/?spm=a2o4n.11387327.header.1.13b0e239emRUK23',
  PK: 'https://www.daraz.pk/mobile-apps?spm=a2a0e.searchlist.header.dewallet.665074f0ulstYU',
  BD: 'https://www.daraz.com.bd/mobile-apps?spm=a2a0e.home.header.dewallet.65ab1b94bC8uwe',
  LK: 'https://www.daraz.lk/mobile-apps?spm=a2a0e.home.header.dewallet.675a462558Mctr',
  MM: undefined,
  NP: 'https://www.daraz.com.np/mobile-apps?spm=a2a0e.11779170.header.dewallet.287d2d2b55MC35&hybrid=1',
};

const LocalConfig = {
  DownloadAppAddress,
};

const serverConfig = get(window, 'im.config', {});

const Config = Object.assign({}, LocalConfig, serverConfig);

export default Config;
