import React from 'react';
import { observer, inject } from 'mobx-react';
import ComPure from '../../components/window-confirm/index';

const Component = inject('Store')(observer(({ Store }) => (
  <ComPure
    sendData={Store.deleteMessageId}
    onCancel={() => {
      Store.unSelectRemoveMessage();
    }}
    onOk={(model) => {
      Store.deleteMessage(model);
    }}
  />
)));

Component.displayName = 'WindowConfirm';

export default Component;
