import React from 'react';
import { observer, inject } from 'mobx-react';
import ComPure from './component';

const Component = inject('Store')(observer(({ Store, onSelectItem }) => (
  <ComPure emojiGroup={Store.emojiGroup} onSelectItem={onSelectItem} />
)));

Component.displayName = 'Emotions';

export default Component;
