// Utility for 2D drawing

export function randomRange(lower, upper) {
  return lower + Math.random() * (upper - lower);
}

export function randomPoint(rect) {
  const x = randomRange(rect.x, rect.x + rect.width);
  const y = randomRange(rect.y, rect.y + rect.height);
  return { x, y };
}

export function movePoint(start, v, t) {
  const dx = v.x * t;
  const dy = v.y * t;
  return { x: start.x + dx, y: start.y + dy };
}
