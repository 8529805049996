import React from "react";
export const IconMailBox = (props) => <svg width="55px" height="47px" viewBox="0 0 55 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" { ...props } >
    <defs>
        <filter x="-1.4%" y="-2.0%" width="102.9%" height="104.0%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
    </defs>
    <g id="messaging-desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="3.0-desktop-messages-empty-set-1" transform="translate(-430.000000, -102.000000)">
            <g id="chat" filter="url(#filter-1)" transform="translate(-1.000000, 0.000000)">
                <g transform="translate(264.000000, 51.000000)" id="hero">
                    <g transform="translate(24.000000, 52.000000)">
                        <g id="icon-mail" transform="translate(147.000000, 0.000000)">
                            <polygon id="Bounds" points="0 0 48 0 48 48 0 48"></polygon>
                            <path d="M40.0519481,5.48571429 L6.88831169,5.48571429 C4.60831169,5.48571429 2.76358442,7.4288961 2.76358442,9.8038961 L2.74285714,35.712987 C2.74285714,38.087987 4.60831169,40.0311688 6.88831169,40.0311688 L40.0519481,40.0311688 C42.3319481,40.0311688 44.1974026,38.087987 44.1974026,35.712987 L44.1974026,9.8038961 C44.1974026,7.4288961 42.3319481,5.48571429 40.0519481,5.48571429 L40.0519481,5.48571429 Z M40.0519481,14.1220779 L23.4701299,24.9175325 L6.88831169,14.1220779 L6.88831169,9.8038961 L23.4701299,20.5993506 L40.0519481,9.8038961 L40.0519481,14.1220779 L40.0519481,14.1220779 Z" id="Shape" fill="#949393"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>