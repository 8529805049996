function prepareClassName(com, { className, mod }) {
  const blockNameParts = [];
  const name = typeof com === 'string' ? com : com.name || com.constructor.name || '';
  const blockName = name.replace(/([A-Z]{1})/g, (a, b) => `-${b.toLowerCase()}`).replace(/^-/, '');
  blockNameParts.push(blockName);

  if (className) {
    blockNameParts.push(className + prepareClassName.ELEMENT_SEP + blockName);
  }

  if (mod) {
    let mods = [];

    if (typeof mod === 'string') {
      mods = [mod];
    } else if (Array.isArray(mod)) {
      mods = mod;
    } else if (typeof mod === 'object') {
      mods = Object.keys(mod).filter(key => !!mod[key]);
    }

    const modStr = mods.map(m =>
      blockName +
        prepareClassName.MOD_SEP +
        prepareClassName.MOD_PREFIX +
        prepareClassName.CLASS_SEP +
        m);
    blockNameParts.push(...modStr);
  }

  return {
    blockName() {
      return blockName;
    },
    block() {
      return blockNameParts.join(' ');
    },
    element(classElement) {
      return blockName + prepareClassName.ELEMENT_SEP + classElement;
    },
  };
}

prepareClassName.ELEMENT_SEP = '__';
prepareClassName.MOD_SEP = '--';
prepareClassName.CLASS_SEP = '-';
prepareClassName.MOD_PREFIX = 'mod';

export default prepareClassName;
