import React from 'react';
import './index.scss';
import bem from '../../utils/bem';

class Button extends React.Component {
  render() {
    const BEM = bem('im-button', this.props);
    return (
      <button {...this.props} className={BEM.block()}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;
