import SessionList from '@alife/session-list';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import tracking from '../../utils/tracking.js';
import { DeleteIcon } from './delete';
import './index.scss';

const explainObj = (i) => toJS(i, { recurseEverything: true });

const mapProps = ({ Store }) => {
  const sessionList = explainObj(Store.sessionList);
  return {
    sessionList,
    loadMore: Store.loadMoreSession,
    draftMap: Store.draftMap.toJS(),
    loading: Store.isMoreSessionLoading,
    openSession: (activeSession, startTime, clearCache) => {
      tracking.trackClkSessionList({ sessionId: activeSession.sessionId });
      return Store.updateActiveSession(activeSession, startTime, clearCache);
    },
    activeSessionId: Store.activeSessionId,
    deleteSession: (sessionId) => {
      tracking.trackClkSessionList({ delete: true });
      return Store.selectRemoveSession(sessionId);
    },
    IconDelete: DeleteIcon,
  };
};

export default inject(mapProps)(observer(SessionList));
