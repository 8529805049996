import React from 'react';
import Login from '../layout/login';
import DefaultView from '../layout/default';
import { IMState } from '@alife/lazada-im-core';

function WindowMod(props) {
  const { imState, loginUser } = props;

  return imState === IMState.notLogin || (loginUser && loginUser.retType === 2) ? <Login /> : <DefaultView {...props} />;
}

export default WindowMod;
