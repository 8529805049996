import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { IconClose } from './icon-close';
import bem from '../../utils/bem';

class MessageViewTitle extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onMinimize: PropTypes.func,
  };
  static defaultProps = {
    title: '',
    onMinimize: () => {},
  };

  constructor(props) {
    super(props);
    this.onMinimize = this.onMinimize.bind(this);
  }

  onMinimize(e) {
    e.preventDefault();
    this.props.onMinimize();
  }

  render() {
    const BEM = bem('message-view-title', this.props);

    return (
      <div className={BEM.block()}>
        <div className={BEM.element('content')}>{this.props.title}</div>
        <div className={BEM.element('action-cont')}>
          <button className={BEM.element('btn-action-close')} onClick={this.onMinimize}>
            <IconClose width={15} height={15} />
          </button>
        </div>
      </div>
    );
  }
}

export default MessageViewTitle;
