import { inject } from 'mobx-react';
import MessageViewContent from './component';

const mapProps = ({ Store }) => ({
  sellerId: Store.sellerId,
  imState: Store.imState,
  activeSessionId: Store.activeSessionId,
  notLogin: Store.notLogin,
  isMessageLoading: Store.isMessageLoading,
  setImagePreviewData: Store.setImagePreviewData,
  isArenaRunning: Store.isArenaRunning,
  arenaAnimator: Store.arenaAnimator,
  emojiGroup: Store.emojiGroup,
});

export default inject(mapProps)(MessageViewContent);
