import SmartCard from '../../components/smart-card';
import { inject } from 'mobx-react';

const mapProps = ({ SmartCardStore }) => ({
  productInfo: SmartCardStore.viewData,
  loading: SmartCardStore.loading,
  onClickSend: SmartCardStore.sendProduct,
  onClickCancel: SmartCardStore.clearProductInfo,
});

export default inject(mapProps)(SmartCard);
