import Model from './base';

class ModelUser extends Model {
  parse(attrs) {
    return {
      id: attrs.userId,
      nick: attrs.nickName,
      avatar: attrs.headUrl,
      type: attrs.userType,
    };
  }
}

export default ModelUser;
