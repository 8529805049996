import classnames from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import bem from '../../utils/bem';
import tracking from '../../utils/tracking';
import ButtonMod from './ButtonMod';
import './index.scss';
import WindowMod from './WindowMod';

class Im extends React.Component {
  static propTypes = {
    onMaximize: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    sdkConfig: PropTypes.shape({}).isRequired,
    notice: PropTypes.number.isRequired,
  };
  state = {
    animationState: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({ animationState: 'opening' });
    }
    if (!nextProps.isOpen && this.props.isOpen) {
      this.setState({ animationState: 'closing' });
    }
  }

  componentDidUpdate() {
    if (this.state.animationState === 'closing') {
      this.setState({ animationState: 'closed' });
    }
    if (this.state.animationState === 'opening') {
      this.setState({ animationState: 'opened' });
    }
  }

  componentDidMount() {
    tracking.trackExpMessageBox();
  }

  onScroll = (e) => {
    e.stopPropagation();
  };

  onMaximize = (e) => {
    e.preventDefault();
    const { onMaximize, sdkConfig } = this.props;
    tracking.trackOpenMessagesBox({
      region: get(sdkConfig, 'region', 'sg'),
      lang: get(sdkConfig, 'lang', 'en'),
    });
    onMaximize && onMaximize();
  };

  render() {
    const { isOpen, notice } = this.props;
    const { animationState } = this.state;
    const BEM = bem('im-app', { ...this.props, mod: { minimize: !isOpen } });
    return (
      <div
        tabIndex={0}
        onKeyUp={this.onKeyUp}
        className={classnames(BEM.block(), animationState)}
        onMouseOver={this.onMous}
      >
        {isOpen ? (
          <WindowMod {...this.props} BEM={BEM} />
        ) : (
          <ButtonMod BEM={BEM} notice={notice} onMaximize={this.onMaximize} />
        )}
      </div>
    );
  }
}
export default Im;
