import { action, computed, observable } from 'mobx';
import { createMessageFromCustomer as messageFactory } from '../models/message';
import requestMtop from '../utils/mtop';
import Tracking from '../utils/tracking';

class SmartCard {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  @observable productInfo = new Map();
  @observable sent = false;
  @observable loading = false;
  @observable sellerId = null;

  @action.bound
  loadSmartCard({ skuId, itemId, sellerId }) {
    requestMtop({
      api: 'mtop.global.im.web.card.product.queryitem',
      data: { skuId, itemId }
    })
      .then(this.setInfo)
      .then(() => this.setSellerId(sellerId))
      .catch((error) => {
        Tracking.trackError({
          api: 'buyer/loadProductInfo',
        });
        console.error(error);
      });
  }

  @action.bound
  setSellerId(sellerId) {
    this.sellerId = sellerId;
  }

  @action.bound
  setInfo(response) {
    if (response && response.result) {
      this.productInfo.merge(response.result);
    }
  }

  @action.bound
  clearProductInfo() {
    this.productInfo.clear();
  }

  @action.bound
  sendProduct() {
    return new Promise((resolve) => {
      if (this.productInfo.size === 0 || this.mainStore.sellerId !== this.sellerId) {
        resolve();
        return;
      }
      const strMap = this.productInfo.toJS();
      const info = Object.create(null);
      for (const [k, v] of strMap) {
        info[k] = v;
      }
      if (!info || !info.skuId) {
        resolve();
        return;
      }
      const message = messageFactory(info, 'product');
      message.templateData.iconUrl = message.templateData.pic;
      message.templateData.oldPrice = message.templateData.originalPrice;
      message.templateData.discount = message.templateData.discountText;
      this.loading = true;
      this.mainStore.sendMessage(message).then(() => {
        this.clearLoadingState();
        this.clearProductInfo();
        resolve();
      });
    });
  }

  @action.bound
  clearLoadingState() {
    this.loading = false;
  }

  @computed
  get viewData() {
    const isInvalidate =
      this.productInfo.size === 0 ||
      !this.mainStore.activeSession ||
      this.mainStore.sellerId !== this.sellerId;
    return isInvalidate ? null : this.productInfo;
  }
}

export default SmartCard;
