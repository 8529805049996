import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@alife/intl-components';
import { intlName } from '../../constants';
import './index.scss';
import { CloseIcon } from './close';
import Button from '../button';
import bem from '../../utils/bem';
import Overlay from '../overlay';

class WindowConfirm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    sendData: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    const { onOk, sendData } = this.props;
    onOk(sendData);
  }

  render() {
    const { onCancel, title } = this.props;
    const BEM = bem('window-confirm', this.props);
    return (
      <div className={BEM.block()}>
        <Overlay onClick={onCancel} className={BEM.blockName()} isShow>
          <div className={BEM.element('dialog')}>
            <div className={BEM.element('dialog-header')}>
              {title ? (
                <span className={BEM.element('dialog-title')}>{title}</span>
              ) : (
                <FormattedMessage
                  intlName={intlName}
                  id="im.windowConfirm.title"
                  className={BEM.element('dialog-title')}
                />
              )}
              <span className={BEM.element('dialog-btn-close')}>
                <Button onClick={onCancel} mod="clear">
                  <CloseIcon />
                </Button>
              </span>
            </div>
            <FormattedMessage
              intlName={intlName}
              id="im.windowConfirm.message"
              className={BEM.element('dialog-content')}
              tagName="div"
            />
            <div className={BEM.element('dialog-footer')}>
              <FormattedMessage intlName={intlName} id="im.windowConfirm.cancel">
                {text => <Button onClick={onCancel}>{text}</Button>}
              </FormattedMessage>
              <FormattedMessage intlName={intlName} id="im.windowConfirm.ok">
                {text => (
                  <Button
                    onClick={this.onOk}
                    className={BEM.element('dialog-btn-ok')}
                    mod="primary"
                  >
                    {text}
                  </Button>
                )}
              </FormattedMessage>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default WindowConfirm;
