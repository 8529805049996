
import React from "react";
export const CloseIcon = () => <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="ic/close" transform="translate(1.000000, 1.000000)" stroke="#808080" stroke-width="2">
            <g>
                <path d="M0.714285714,0.714285714 L9.28571429,9.28571429" id="Stroke-23"></path>
                <path d="M9.28571429,0.714285714 L0.714285714,9.28571429" id="Stroke-25"></path>
            </g>
        </g>
    </g>
</svg>