import withI18n from '@ali/lzdmod-intl-utils';
import { defineMessages } from '@alife/universal-intl';
import React from 'react';
import SkeletonSessionsList from '../../components/skeleton/sessions-list';
import { intlName } from '../../constants';
import tracking from '../../utils/tracking.js';
import SessionList from './ob-session-list';

const messages = defineMessages({
  draft: {
    id: 'im.sesstionItem.draft',
    defaultMessage: 'Draft',
  },
});

class SessionView extends React.Component {
  componentDidMount() {
    tracking.trackExpSessionList();
  }
  render() {
    const { translate, loading } = this.props;
    const locale = {
      draft: translate('draft'),
    };
    return (
      <div className="session-view">
        {loading ? <SkeletonSessionsList /> : <SessionList displaySearchBar={false} locale={locale} />}
      </div>
    );
  }
}

export default withI18n(messages, intlName)(SessionView);
