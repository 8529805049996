import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dummy from '../dummy';
import './index.scss';

const SkeletonMessage = ({ type, lines }) => (
  <div
    className={classNames('skeleton-message', {
      [`skeleton-message_type_${type}`]: type,
    })}
  >
    <div className="skeleton-message__content">
      <Dummy className="skeleton-message__date" />
      <div className="skeleton-message__lines">
        {Array(lines)
          .fill()
          .map((_, index) => index)
          .map(item => <Dummy className="skeleton-message__line" key={item} />)}
      </div>
    </div>
    <Dummy className="skeleton-message__avatar" />
  </div>
);

SkeletonMessage.propTypes = {
  type: PropTypes.oneOf(['seller', 'buyer', 'session']),
  lines: PropTypes.number,
};

SkeletonMessage.defaultProps = {
  type: 'buyer',
  lines: 3,
};

export default SkeletonMessage;
