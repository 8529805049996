import { observable, extendObservable, action } from 'mobx';

export default class ModelBase {
  constructor(attrs = {}, opt = {}) {
    extendObservable(this, {
      data: observable.map({}),
    });

    if (attrs) {
      this.putData(attrs, opt);
    }
  }

  putData = action('ModelBase:putData', (attrs, opt = {}) => {
    this.data.merge(opt.noParse ? attrs : this.parse(attrs));
  });

  getData(key) {
    if (key) {
      return this.data.get(key);
    }
    return this.data.toJS();
  }

  parse(attrs) {
    return { ...attrs };
  }
}
