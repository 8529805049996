import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class Emotion extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    imgUrl: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    catId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    const { onClick, id, catId, name, imgUrl, smallImgUrl } = this.props;

    if (typeof onClick === 'function') {
      onClick({ id, catId, title: name, imgUrl, smallImgUrl });
    }
  }

  render() {
    const { name, width, height, imgUrl } = this.props;

    return (
      <div className="emoji-item" onClick={this.onClick}>
        <img
          className="emoji-item-pic"
          width={width}
          height={height}
          src={imgUrl}
          style={{ height: `${height}px` }}
        />
        <span className="emoji-item-label">{name}</span>
      </div>
    );
  }
}
