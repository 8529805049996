// IM plugin loader

class PluginLoader {
  static _instance = null;

  static getLoader(store) {
    if (!PluginLoader._instance) {
      PluginLoader._instance = new PluginLoader(store);
    }
    return PluginLoader._instance;
  }

  constructor(store) {
    this.store = store;
    this.store.plugins = [];
  }

  loadPlugin(plugin) {
    try {
      plugin.init && plugin.init(this.store);
      this.store.plugins.push(plugin);
    } catch (err) {
      console.error('Plugin loading fails', err);
    }
  }

  loadPlugins(plugins) {
    if (!plugins) {
      return;
    }
    plugins.forEach(this.loadPlugin, this);
  }
}

export default PluginLoader;
