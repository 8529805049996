import React from 'react';
import PropTypes from 'prop-types';
import Message from '../message';
import './index.scss';

const SkeletonSessionsList = ({ items }) => (
  <div className="skeleton-sessions-list">
    {Array(items)
      .fill()
      .map((_, index) => index)
      .map(item => <Message lines={1} key={item} type="session" />)}
  </div>
);

SkeletonSessionsList.propTypes = {
  items: PropTypes.number,
};

SkeletonSessionsList.defaultProps = {
  items: 6,
};

export default SkeletonSessionsList;
