import React from 'react';
import './index.scss';

class Arena extends React.Component {
  container = null;

  canvas = null;

  isAnimating = false;

  stage = null;

  animator = null;

  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.resizeStage().then(() => {
      if (this.initStage()) {
        this.startAnimation();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.stopAnimation();
  }

  handleResize = () => {
    this.resizeStage();
  };

  resizeStage() {
    return new Promise((resolve) => {
      const box = this.container.getBoundingClientRect();
      this.setState({
        width: box.width,
        height: box.height,
      }, resolve);
    });
  }

  initStage() {
    const { arenaAnimator: Animator } = this.props;
    const { width, height } = this.state;
    this.stage = {
      // time ticker
      _st_: Date.now(),
      tick: 0,
      // bound box
      width,
      height,
    };
    let success;
    try {
      this.animator = new Animator(this.stage);
      success = true;
    } catch (err) {
      console.error(err);
      success = false;
    }
    return success;
  }

  startAnimation() {
    const ctx = this.canvas.getContext('2d');
    this._drawNextFrame(ctx);
    this.isAnimating = true;
  }

  stopAnimation() {
    this.isAnimating = false;
  }

  _tick() {
    this.stage.tick = Date.now() - this.stage._st_;
  }

  _drawNextFrame(ctx) {
    requestAnimationFrame(() => {
      if (!this.isAnimating) {
        return;
      }
      this._tick();
      this.animator.animate(ctx, this.stage);
      this._drawNextFrame(ctx);
    });
  }

  render() {
    const { width, height } = this.state;
    return (
      <div className="lzd-arena" ref={(el) => { this.container = el; }}>
        <canvas
          className="arena-stage"
          ref={(el) => { this.canvas = el; }}
          width={width}
          height={height}
        />
      </div>
    );
  }
}

export default Arena;
