import requestAjax from './ajax';
import requestMtop from './mtop';

function loadImageData(file) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.src = URL.createObjectURL(file);
  });
}

class Uploader {
  constructor(options) {
    this.options = options;
    this.accessToken = undefined;
    this.validUtil = 0;
  }

  initToken() {
    return requestMtop({
      api: this.options.getTokenAPI,
      data: {
        biz: this.options.uploadKey,
      }
    }).then(resp => {
      const data = resp.data;
      const { accessToken, validUtil } = data;
      this.accessToken = accessToken;
      this.validUtil = validUtil;
    });
  }

  parseToken(accessToken = '') {
    let token = undefined;
    const mat = accessToken.match(/:([^:]*)$/);
    if (mat) {
      token = mat[1];
    }
    return token;
  }

  uploadFile(file) {
    return requestAjax('POST', this.options.uploadAPI, {
      requestType: 'form',
      data: {
        accessToken: this.parseToken(this.accessToken),
        file,
      },
    });
  }

  getFileUri(file) {
    return this.uploadFile(file).then(resp => {
      const uri = resp.data && resp.data.uri;
      return this.options.staticRoot + uri;
    });
  }

  getImageFileData(file) {
    return Promise.all([
      this.getFileUri(file),
      loadImageData(file),
    ]).then((ref) => {
      return {
        imgUrl: ref[0],
        width: ref[1].width,
        height: ref[1].height,
      };
    });
  }
}

/**
 * Get file uploader instance
 * @param {object} options - file upload options
 */
function getUploader(options) {
  return new Uploader({
    getTokenAPI: 'mtop.lazada.filebroker.buyer.accesstoken.get',
    uploadAPI: '//filebroker-lzd-sg.alibaba.com/upload/common/form/im',
    uploadKey: 'daraz-im',
    staticRoot: 'https://sg-live.slatic.net',
    ...options
  });
}

export default getUploader;
