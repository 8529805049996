import React from 'react';
import { observer, inject } from 'mobx-react';
import ComPure from './component';
import { createMessageFromCustomer } from '../../models/message';

const Component = inject('Store', 'SmartCardStore')(observer(({ Store, SmartCardStore }) => (
  <ComPure
    activeSession={Store.activeSession}
    setDraft={Store.setDraft}
    draft={Store.currentDraft}
    onSubmitText={(msg) => {
      if (msg.length) {
        SmartCardStore.sendProduct().then(() => {
          Store.sendMessage(createMessageFromCustomer({ txt: msg }));
        });
      }
    }}
    onSubmitEmoji={(msgData) => {
      if (msgData) {
        SmartCardStore.sendProduct().then(() => {
          Store.sendMessage(createMessageFromCustomer({ txt: msgData.id }, 'emotion'));
        });
      }
    }}
    onFilesSelect={Store.setSelectedFiles}
    inputMessage={Store.inputMessage}
    updateInputMessage={Store.updateInputMessage}
  />
)));

Component.displayName = 'Message';

export default Component;
