import { inject } from 'mobx-react';
import ImagePreview from './component';

const mapProps = ({ Store }) => ({
  isUploadedImage: Store.imagePreviewData.isUploadedImage,
  src: Store.imagePreviewData && Store.imagePreviewData.preview,
  showHeader: Store.imagePreviewData && Store.imagePreviewData.isUploadedImage,
  showFooter: !Store.imagePreviewData || !Store.imagePreviewData.isUploadedImage,
  hideSubmit: Store.imagePreviewData && Store.imagePreviewData.hideSubmit,
  onCancel: Store.clearImagePreviewData,
  onSubmit: Store.uploadImage,
  uploadProgress: Store.uploadProgress,
});

export default inject(mapProps)(ImagePreview);
