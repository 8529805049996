/**
 * ref from:
 * https://github.com/koajs/compose/blob/master/index.js
 */

// tslint:disable-next-line: no-empty
const emptyFunc = () => {};

function isPromise(obj) {
  return obj && typeof obj.then === 'function';
}

function runPlugins(store, hookName, option, hookPromise) {
  const { plugins } = store;
  if (!Array.isArray(plugins)) { return false; }
  let index = -1;
  return dispatch(0);
  function dispatch(i) {
    if (i === plugins.length) { return hookPromise(); }
    if (i <= index) { return Promise.reject(new Error('next() called multiple times')); }
    index = i;
    const plugin = plugins[i] || {};
    const fn = plugin[hookName];
    if (!fn) { return dispatch(i + 1); }
    try {
      return Promise.resolve(fn(store, dispatch.bind(null, i + 1), option));
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

const pluginfyMethod = (store, methodName) => {
  const cacheMethod = store[methodName] || emptyFunc; // offer empty function for new command
  if (cacheMethod.pluginfyed) { return true; } // do nothing for method already pluginfyed before
  const pluginfyedMethod = (...args) => {
    const cacheMethodPromise = () => {
      const result = cacheMethod.call(store, ...args);
      return isPromise(result) ? result : Promise.resolve(result);
    };
    return runPlugins(store, methodName, { args: [...args] }, cacheMethodPromise);
  };
  pluginfyedMethod.pluginfyed = true; // add mark for pluginfyed method
  store[methodName] = pluginfyedMethod;
  return Promise.resolve(methodName);
};

const Pluginfy = () => ({
  name: 'Pluginfy',
  init(store) {
    if (store.pluginfyMethod) {
      throw new Error('pluginfy failed, because pluginfyMethod is occupied');
    }
    store.pluginfyMethod = pluginfyMethod;
  },
});

export default Pluginfy;
