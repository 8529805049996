import React from "react";
export const IconPlus = () => <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Plus">
            <g id="Group-2">
                <circle id="Oval-37" stroke="#999999" stroke-width="0.5" cx="13.5" cy="13.5" r="13.25"></circle>
                <path d="M14.1423898,12.8446102 L14.1423898,7.65031757 C14.1423898,7.29162854 13.8523788,7.00123708 13.4936898,7.00000049 C13.1349373,7.00123708 12.8446093,7.29162854 12.8446093,7.65031757 L12.8446093,12.8446102 L7.64906414,12.8446102 C7.29099341,12.8446102 6.99999951,13.1349383 6.99999951,13.4930566 C6.99999951,13.8523798 7.29099341,14.1423907 7.64906414,14.1423907 L12.8446093,14.1423907 L12.8446093,19.3376188 C12.8446093,19.6963712 13.1349373,19.9873017 13.4936898,19.9873017 C13.8523788,19.9873017 14.1423898,19.6963712 14.1423898,19.3376188 L14.1423898,14.1423907 L19.3382361,14.1423907 C19.6969885,14.1423907 19.987919,13.8523798 19.9873007,13.4930566 C19.9873007,13.1349383 19.6963702,12.8446102 19.3382361,12.8446102 L14.1423898,12.8446102 Z" id="iconfont-tianjia-2" fill="#5F646E" transform="translate(13.493651, 13.493651) rotate(-270.000000) translate(-13.493651, -13.493651) "></path>
            </g>
        </g>
    </g>
</svg>