import { randomPoint, randomRange, movePoint } from '../utils/graphic';

const FALLING_DURATION = 3500;
const POINT_AMOUNT = 50;
const MOVING_VELOCITY = 0.25;

const keywordMap = {};

let keywordPattern;

function compileRegex() {
  const keys = Object.getOwnPropertyNames(keywordMap);
  if (keys.length === 0) {
    return null;
  }
  return new RegExp(`\\s(${keys.join('|')})\\s`, 'i');
}

function searchKeyword(text) {
  if (keywordPattern === null) {
    return '';
  }
  if (!keywordPattern) {
    keywordPattern = compileRegex();
  }
  const mat = ` ${text} `.match(keywordPattern);
  if (!mat) {
    return '';
  }
  return mat[1];
}

function createAnimator(emojiUrl) {
  return class FallingDownAnimator {
    constructor(stage) {
      // init drawing image
      const img = new Image();
      img.src = emojiUrl;
      this.icon = {
        img,
        width: 36,
        height: 36,
      };
      // init start points
      const starts = [];
      for (let i = 0; i < POINT_AMOUNT; i++) {
        starts.push(randomPoint({
          x: 0,
          y: -500,
          width: stage.width,
          height: 500,
        }));
      }
      this.starts = starts;
      // init velocity vectors
      const velocities = [];
      for (let i = 0; i < POINT_AMOUNT; i++) {
        const vx = randomRange(-0.2, 0.2);
        velocities.push({
          x: MOVING_VELOCITY * vx,
          y: MOVING_VELOCITY * 1
        });
      }
      this.velocities = velocities;
    }

    animate(ctx, stage) {
      ctx.clearRect(0, 0, stage.width, stage.height);
      for (let i = 0; i < POINT_AMOUNT; i++) {
        const sp = this.starts[i];
        const v = this.velocities[i];
        const p = movePoint(sp, v, stage.tick);
        ctx.drawImage(this.icon.img, p.x, p.y, this.icon.width, this.icon.height);
      }
    }
  };
}

function FallingDownPlugin() {
  let fallingTimer = 0;

  function startFallingDown(store, emojiUrl) {
    stopFallingDown(store);
    store.updateData({
      arenaAnimator: createAnimator(emojiUrl),
      isArenaRunning: true,
    });
    fallingTimer = setTimeout(() => {
      stopFallingDown(store);
    }, FALLING_DURATION);
  }

  function stopFallingDown(store) {
    if (!store.isArenaRunning) {
      return;
    }
    clearTimeout(fallingTimer);
    store.updateData({
      isArenaRunning: false,
      arenaAnimator: null,
    });
  }

  function detectKeyword(store, msg) {
    const text = msg.templateData.txt;
    const keyword = searchKeyword(text);
    if (keyword !== '') {
      startFallingDown(store, keywordMap[keyword]);
      return true;
    }
    return false;
  }

  return {
    init(store) {
      store.pluginfyMethod(store, 'sendMessage');
      store.pluginfyMethod(store, 'receiveNewMessage');
      store.pluginfyMethod(store, 'updateActiveSessionStart');
    },
    async sendMessage(store, next, option) {
      await next();
      const [message] = option.args;
      detectKeyword(store, message);
    },
    async receiveNewMessage(store, next, option) {
      await next();
      const [messages] = option.args;
      for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i];
        if (detectKeyword(store, message)) {
          break;
        }
      }
    },
    async updateActiveSessionStart(store, next) {
      await next();
      stopFallingDown(store);
    }
  };
}

export default FallingDownPlugin;
