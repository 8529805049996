import React from 'react';
import { Scroller, Tab } from '@alife/simple-ui';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';
import Emotion from './emotion';
import './index.scss';

class Emotions extends React.PureComponent {
  static propTypes = {
    emojiGroup: PropTypes.arrayOf({}).isRequired,
    onSelectItem: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      emojiList: [],
    };
  }
  componentDidMount() {
    const { emojiGroup } = this.props;
    this.setState({
      emojiList: emojiGroup[0]?.list,
    });
  }
  @boundMethod
  changeEmojiGroup(tag) {
    const { emojiGroup } = this.props;
    const group = emojiGroup.find(g => g.tag === tag);
    this.setState({
      emojiList: group.list,
    });
    setImmediate(() => {
      window.resizeBy(0, 0);
    });
  }
  render() {
    const { emojiGroup, onSelectItem } = this.props;
    const { emojiList } = this.state;
    const options = emojiGroup.map(group => ({
      value: group.tag,
      label: (
        <div className="ripple">
          <img src={group.icon} alt={`group-${group.tag}`} />
        </div>
      ),
    }));
    const firstOption = options[0] || {};
    return (
      <div className="im-emotions">
        <Scroller customScrollBar className="emoji-list">
          {emojiList?.map(emotion => (
            <Emotion
              key={emotion.key}
              id={emotion.key}
              {...emotion}
              width={70}
              height={70}
              onClick={onSelectItem}
              mod="cursor"
              className="emotions"
            />
          ))}
        </Scroller>
        <Tab options={options} defaultValue={firstOption.value} onChange={this.changeEmojiGroup} />
      </div>
    );
  }
}

export default Emotions;
