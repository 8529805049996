import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const SkeletonDummy = ({ className, style }) => (
  <div className={classNames('skeleton-dummy', className)} style={style} />
);

SkeletonDummy.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
};

SkeletonDummy.defaultProps = {
  className: null,
  style: null,
};

export default SkeletonDummy;
