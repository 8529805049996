import React from 'react';
import { FormattedMessage } from '@alife/intl-components';
import { intlName } from '../../constants';
import './index.scss';
import { Icon } from './icon-messages';
import bem from '../../utils/bem';

export default function SessionToolbar({ ...props }) {
  const BEM = bem('session-toolbar', props);
  return (
    <div className={BEM.block()}>
      <span className={BEM.element('icon')}>
        <Icon />
      </span>
      <FormattedMessage
        intlName={intlName}
        id="im.common.windowTitle"
        defaultMessage="Messages"
        className={BEM.element('title')}
      />
    </div>
  );
}
