import React from 'react';
import SessionToolbar from '../../../components/session-toolbar';
import ImagePreview from '../../image-preview';
import MessageView from '../../message-view';
import SessionView from '../../session-view';
import WindowConfirmMessDel from '../../window-confirm-mess-del';
import WindowConfirmSessDel from '../../window-confirm-sess-del';

function DefaultView(props) {
  const { isExistImagePreviewData, isShowConfirmSessDel, isShowConfirmMessDel } = props;
  return (
    <span className="cont-maximize">
      <div className="left-sidebar">
        <SessionToolbar />
        <SessionView />
      </div>
      <MessageView />
      {isExistImagePreviewData && <ImagePreview />}
      {isShowConfirmSessDel && <WindowConfirmSessDel />}
      {isShowConfirmMessDel && <WindowConfirmMessDel />}
    </span>
  );
}

export default DefaultView;
