export const MSG_TYPE_TO_ID = {
  text: 1,
  system: 2,
  img: 3,
  emotion: 4,
  order: 10007,
  product: 10006,
  voucher: 10008,
  follow: 10010,
  refundOrder: 10011,
  myOrder: 10012,
  confirmOrder: 10013,
};

export default {
  MSG_TYPE_TO_ID,
};

export const intlName = 'webIM';
