import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@alife/intl-components';
import { intlName } from '../../constants';
import { IconMessage } from './icon-messages';

function ButtonMod(props) {
  const { BEM, onMaximize, notice } = props;
  return (
    <button className={BEM.element('cont-minimize')} onClick={onMaximize}>
      <div className="button-mod-box">
        <IconMessage />
        <FormattedMessage
          intlName={intlName}
          id="im.common.windowTitle"
          defaultMessage="Messages"
          tagName="div"
          className={BEM.element('minimize-title')}
        />
        {notice > 0 && <div className={BEM.element('minimize-badge')}>{notice}</div>}
      </div>
    </button>
  );
}
ButtonMod.propTypes = {
  BEM: PropTypes.shape({}).isRequired,
  onMaximize: PropTypes.func.isRequired,
  notice: PropTypes.number.isRequired
};
export default ButtonMod;
