import React from 'react';
import { observer, inject } from 'mobx-react';

import ComPure from './component';

const Component = inject('Store')(observer(({ Store }) => (
  <ComPure
    title={Store.activeSession ? Store.activeSession.title : ''}
    onMinimize={() => {
      Store.openView(false);
    }}
  />
)));

Component.displayName = 'MessageViewTitle';

export default Component;
