import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bem from '../../utils/bem';
import './index.scss';

class Overlay extends Component {
  static propTypes = {
    isShow: PropTypes.bool.isRequired,
    children: PropTypes.node,
    mod: PropTypes.oneOfType([PropTypes.shape()]),
    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    onClick: null,
    mod: {},
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    const { onClick } = this.props;
    onClick && onClick();
  }

  render() {
    const { isShow, mod, children } = this.props;
    const BEM = bem('overlay', { ...this.props, ...{ mod: { ...mod, show: isShow } } });

    return (
      <div onClick={this.onClick} className={BEM.block()}>
        {children}
      </div>
    );
  }
}

export default Overlay;
