import Tracking from '../utils/tracking';
import ImCore, { EVENT } from '@alife/lazada-im-core';
import '../sdk';
import Stroe from '../stores/store';
import Env from '../utils/env';
import { initLocale } from '../utils/locale';
import { checkLibAccs } from '../sdk';
import { updateActiveSessionStart, updateActiveSessionSuccess } from './event';

/**
 * 检查 libAccs
 */
checkLibAccs();

let imCoreInstance;
export default {
  setConfig(config) {
    imCoreInstance = new ImCore({
      store: {
        stateClass: Stroe,
      },
      imsdk: {
        config: {
          appKey: Env.appKey,
          umidToken: () => Env.getUmidToken(),
          type: 'lazada',
          accountType: 1,
          fromCode: 'sc_buyer_default',
          lang: config.lang,
          region: config.region,
          delayTime: 100,
          env: Env.env,
          onlySupportB2C: true,
        },
      },
    });
    initLocale(config.lang);
    return this;
  },
  init() {
    const instance = this.getInstance();
    instance
      .init()
      .then(() => {
        instance.store.fetchEmojiList();
      })
      .catch((err) => {
        if (err && err.type === 'USER_NOT_LOGIN') {
          return;
        }
        Tracking.trackError({
          api: 'buyer/init',
          type: err.type,
          region: instance.imsdkConfig ? instance.imsdkConfig.region : 'unknow',
        });
      });
    instance.event.on(EVENT.UPDATE_ACTIVE_SESSION_START, updateActiveSessionStart);
    instance.event.on(EVENT.UPDATE_ACTIVE_SESSION_SUCCESS, updateActiveSessionSuccess);
    return this;
  },
  getInstance() {
    return imCoreInstance;
  },
};
