import React from "react";
export const IconSmile = () => {
    return <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <filter x="-1.3%" y="-13.5%" width="103.6%" height="126.9%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="2" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.201964447 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-Chat-text" transform="translate(-303.000000, -9.000000)" fill="#9E9E9E" fill-rule="nonzero">
            <g id="Group" filter="url(#filter-1)">
                <path d="M321.260745,24 L321.638607,24 L322.282357,24 C322.282357,27.4708681 319.520631,30.2873936 316.030244,30.2873936 C312.540252,30.2873936 309.714286,27.4707912 309.714286,24 L310.765383,24 C310.765383,27.0932182 313.352781,29.2667411 316.030244,29.2667411 C318.707707,29.2667411 321.260745,27.0932869 321.260745,24 Z M319.143148,19.8095238 C319.721567,19.8095238 320.190476,20.2783028 320.190476,20.8571429 C320.190476,21.4354006 319.721567,21.9047619 319.143148,21.9047619 C318.564147,21.9047619 318.095238,21.4354006 318.095238,20.8571429 C318.095238,20.2783028 318.564147,19.8095238 319.143148,19.8095238 Z M312.84934,19.8095238 L312.865524,19.8095238 C313.439475,19.8095238 313.904762,20.2783028 313.904762,20.8571429 C313.904762,21.4354006 313.439475,21.9047619 312.865524,21.9047619 L312.84934,21.9047619 C312.274811,21.9047619 311.809524,21.4354006 311.809524,20.8571429 C311.809524,20.2783028 312.274811,19.8095238 312.84934,19.8095238 Z M316,13 C309.924713,13 305,17.9249098 305,23.9997221 C305,30.0749709 309.924594,35 316,35 C322.075406,35 327,30.0749709 327,23.9997221 C327,17.9249098 322.075287,13 316,13 L316,13 Z M316,14.047619 C321.496693,14.047619 325.952381,18.5034845 325.952381,23.9997221 C325.952381,29.4964076 321.496801,33.952381 316,33.952381 C310.503199,33.952381 306.047619,29.4964076 306.047619,23.9997221 C306.047619,18.5034845 310.503307,14.047619 316,14.047619 L316,14.047619 Z" id="ic-smiley"></path>
            </g>
        </g>
    </g>
</svg>;
}