import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from '@alife/universal-intl';
import withI18n from '@ali/lzdmod-intl-utils';
import bem from '../../utils/bem';
import Overlay from '../../components/overlay';
import Button from '../../components/button';
import { intlName } from '../../constants';
import './index.scss';
import { IconClose } from './close';
import { IconDownload } from './download';

const messages = defineMessages({
  cancel: {
    id: 'im.imagePreview.cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'im.imagePreview.send',
    defaultMessage: 'Send',
  },
});

@withI18n(messages, intlName)
class ImagePreview extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    showFooter: PropTypes.bool,
    showHeader: PropTypes.bool,
    isUploadedImage: PropTypes.bool,
    uploadProgress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    uploadProgress: '',
    showFooter: false,
    showHeader: false,
    isUploadedImage: false,
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel() {
    this.props.onCancel();
  }

  onSubmit(e) {
    e.stopPropagation();

    const { onSubmit } = this.props;
    onSubmit && onSubmit();
  }

  renderFooter(BEM, uploading) {
    const { translate, hideSubmit } = this.props;

    return (
      <div className={BEM.element('footer')}>
        <Button onClick={this.onCancel}>{translate('cancel')}</Button>
        {!hideSubmit &&
        <Button
          disabled={uploading}
          onClick={this.onSubmit}
          className={BEM.blockName()}
          mod="primary"
        >
          {translate('send')}
        </Button>
        }
      </div>
    );
  }

  renderHeader(BEM) {
    const { src } = this.props;

    return (
      <div className={BEM.element('header')}>
        <a
          className={BEM.element('download')}
          href={src}
          download="image"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IconDownload width={21.6} height={27} />
        </a>
        <IconClose
          onClick={this.onCancel}
          className={BEM.element('close')}
          width={16}
          height={16}
        />
      </div>
    );
  }

  render() {
    const { showHeader, src, uploadProgress, isUploadedImage, showFooter } = this.props;
    const BEM = bem('image-preview', {
      mod: {
        'is-uploaded': isUploadedImage,
      },
    });
    const uploading = uploadProgress !== null && uploadProgress !== 0 && uploadProgress !== '';

    return (
      <div className={BEM.block()}>
        <Overlay isShow className={BEM.blockName()}>
          {showHeader && this.renderHeader(BEM)}
          <div className={BEM.element('content')}>
            <div className={BEM.element('img-wrap')}>
              <img className={BEM.element('img')} src={src} />
              {uploading ? (
                <div className={BEM.element('progress')}>
                  <div
                    className={BEM.element('progress-inner')}
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {showFooter && this.renderFooter(BEM, uploading)}
        </Overlay>
      </div>
    );
  }
}

export default ImagePreview;
