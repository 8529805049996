import readImageToPreview from '../utils/readImageToPreview';
import getImageUploader from '../utils/uploader';
import { ImCoreStateClass } from '@alife/lazada-im-core';
import get from 'lodash.get';
import { action, computed, extendObservable, observable, runInAction } from 'mobx';
import { createMessageFromCustomer } from '../models/message';
import ModelUser from '../models/user';

class Store extends ImCoreStateClass {
  localIds = 0;
  locale = 'en';
  @observable isOpen = false;
  /* Please use decorator instead of extendObservable  */
  @observable uploadProgress = 0;
  @observable selectedFiles = null;

  @observable imagePreviewData = null;
  @observable windowConfirmData = null;
  @observable deleteMessageId = null;
  @observable currentUser = new ModelUser();

  // Arena {
  @observable isArenaRunning = false;

  arenaAnimator = null;
  // }

  constructor() {
    super();
    /* please avoid this in new code */
    extendObservable(this, {
      resendMessage: action('Store:resendMessage', (id) => {
        const message = this.messageList.find(i => i.messageId === id);

        if (message) {
          this.sendMessage(message).then(() => this.deleteMessage(id));
        }
      }),

      selectRemoveSession: action('Store:selectRemoveSession', (session) => {
        this.windowConfirmData = { title: session.title, id: session.sessionId };
      }),

      unSelectRemoveSession: action('Store:unSelectRemoveSession', () => {
        this.windowConfirmData = null;
      }),

      selectRemoveMessage: action('Store:selectRemoveMessage', (model) => {
        this.deleteMessageId = model;
      }),

      unSelectRemoveMessage: action('Store:unSelectRemoveMessage', () => {
        this.deleteMessageId = null;
      }),
    });
  }

  @action.bound
  openView(isOpen) {
    this.isOpen = isOpen;
  }

  @action.bound
  setLoadingMessages(value = false) {
    this.loadingMessages = value;
  }

  @action.bound
  setLoadingSessions(value = false) {
    this.loadingSessions = value;
  }

  @computed
  get seller() {
    return get(this, 'activeSession.toUser', {});
  }

  @computed
  get sellerId() {
    return get(this, 'activeSession.toUser.userId', {});
  }

  @computed
  get user() {
    return new ModelUser(this.loginUser || {});
  }

  @action.bound
  sendMessage(msg) {
    // e.g enNP cc @泽莙
    const xLang = `${window.g_config.language || 'en'}${window.g_config.regionID || ''}`;
    const msgObj = { ...msg };
    msgObj.ext = { ...msgObj.ext, xLang };
    return super.sendMessage(msgObj);
  }

  @action.bound
  receiveNewMessage(msgList) {
    return super.receiveNewMessage(msgList);
  }

  @action.bound
  uploadImage() {
    if (!this.selectedFiles) {
      return;
    }

    this.uploadProgress = 1;

    const inProgress = (done, total) =>
      runInAction(() => {
        this.uploadProgress = done / total * 100 || 1;
      });
    const clearUploadedFiles = () =>
      runInAction(() => {
        this.uploadProgress = 0;
        this.selectedFiles = null;
        this.clearImagePreviewData();
      });

    // reset firstly
    this.uploadProgress = 0;

    // daraz or others
    const uploader = getImageUploader();
    uploader.initToken()
        .then(() => uploader.getImageFileData(this.selectedFiles[0]))
        .then((imgData) => this.sendMessage(createMessageFromCustomer(imgData, 'img')))
        .then(clearUploadedFiles);
  }

  @action.bound
  setSelectedFiles(files) {
    readImageToPreview(files)
      .then((result) => {
        this.setImagePreviewData(result);
        this.selectedFiles = files;
      })
      .catch((err) => {
        console.error('Error on loading preview image', err);
      });
  }

  @action.bound
  setImagePreviewData(preview, options = {}) {
    const { isUploadedImage = false, hideSubmit = false } = options;
    this.imagePreviewData = {
      preview,
      isUploadedImage,
      hideSubmit,
    };
  }

  @action.bound
  clearImagePreviewData() {
    this.imagePreviewData = null;
  }

  getId = () => {
    this.localIds = this.localIds + 1;
    return this.localIds;
  };
}

export default Store;
