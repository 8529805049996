import { siteName } from './host';

const PAGE_INTENT = {
  login: 'login',
  register: 'register',
};

function getSiteSuffix() {
  if (!siteName) {
    return;
  }
  const host = window.location.host;
  const mat = host.match(new RegExp(`(${siteName}\..+)$`));
  if (mat) {
    return mat[1];
  }
  return;
}

function getPageUrl(page) {
  const protocol = window.location.protocol;
  const siteSuffix = getSiteSuffix();
  const backUrl = window.location.href;
  return `${protocol}//member.${siteSuffix}/user/${page}?redirect=${encodeURIComponent(backUrl)}`;
}

function gotoPage(page) {
  window.location = getPageUrl(page);
}

export {
  PAGE_INTENT,
  getPageUrl,
  gotoPage,
}
