import { inject } from 'mobx-react';
import ComPure from './component';

const mapProps = ({ Store }) => ({
  onLine: Store.onLine,
  isShowViewField: !!Store.activeSession && !!Store.activeSession.sessionId,
  notLogin: Store.notLogin,
});

export default inject(mapProps)(ComPure);
