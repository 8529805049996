import { MSG_TYPE_TO_ID } from '../constants';

const ModelMessage = {
  DIRECT_TO_ME: 'DIRECT_TO_ME',
  DIRECT_TO_OTHER: 'DIRECT_TO_OTHER',
};

export function createMessageFromCustomer(content = { txt: '' }, type = 'text') {
  return {
    templateData: content,
    templateType: MSG_TYPE_TO_ID[type] || null,
  };
}

export default ModelMessage;
