export const updateActiveSessionStart = ({ store }) => {
  store.updateData({
    isUpdatingActiveSession: true,
  });
};

export const updateActiveSessionSuccess = ({ store }) => {
  store.updateData({
    isUpdatingActiveSession: false,
  });
};

export function createSession(
  imInstance,
  { accountId, accountType = 2, fromCode = 'sc_buyer_default' }
) {
  if (imInstance === null) {
    return Promise.reject("Can't find imInstance");
  }
  return new Promise((resolve, reject) => {
    if (imInstance === null) {
      reject(null);
    }
    imInstance.im
      .createSession(accountId, accountType, fromCode)
      .then((session) => {
        const { updateActiveSession } = imInstance.store;
        const newSession = { ...session, fromCode };
        updateActiveSession(newSession);
        resolve(newSession);
      })
      .catch(() => {
        reject(null);
      });
  });
}
