import { inject, observer } from 'mobx-react';
import App from './component';

const mapProps = ({ Store }) => ({
  isOpen: Store.isOpen,
  isExistImagePreviewData: Store.imagePreviewData !== null,
  isShowConfirmSessDel: Store.windowConfirmData !== null,
  isShowConfirmMessDel: Store.deleteMessageId !== null,
  notice: Store.totalUnread,
  sdkConfig: Store.sdkConfig,
  imState: Store.imState,
  loginUser: Store.loginUser,
  onMaximize: () => {
    Store.openView(true);
  },
});

export default inject(mapProps)(observer(App));
