import MessageList, { getCardMap, CardTypeCode } from '@alife/lazada-message-list';
import React from 'react';
import { inject } from 'mobx-react';
import { sendChoice } from '../../services/interaction';
import requestMtop from '../../utils/mtop';
import getGovHost, { getSiteMainDomain } from '../../utils/host';
import { createSymbolReplace } from './symbol-replace';

function updateTextCard(CardMap) {
  const CardText = CardMap.get(CardTypeCode.CardText);
  function CardTextWithTranslate(props) {
    return (
      <CardText {...props} displayTranslate />
    );
  }
  CardMap.set(CardTypeCode.CardText, CardTextWithTranslate);
}

function updateUnpaidOrderCard(CardMap) {
  const CardUnpaidOrder = CardMap.get(CardTypeCode.CardUnpaidOrder);
  function CardUnpaidOrderWithFunc(props) {
    return (
      <CardUnpaidOrder
        {...props}
        onClick={data => window.open(data.toPayPCUrl4Buyer)}
      />
    );
  }
  CardMap.set(CardTypeCode.CardUnpaidOrder, CardUnpaidOrderWithFunc);
}

function enhanceCard(setImagePreviewData, emojiGroup) {
  const CardMap = getCardMap();
  const CardFollow = CardMap.get(CardTypeCode.CardFollow);
  function CardFollowWithFunc(props) {
    return (
      <CardFollow
        {...props}
        onClick={content => {
          requestMtop({
            api: content.actionUrl,
            data: {
              action_code: content.action,
              param_json: JSON.stringify(content),
            },
          });
        }}
      />
    );
  }
  CardMap.set(CardTypeCode.CardFollow, CardFollowWithFunc);

  const CardProduct = CardMap.get(CardTypeCode.CardProduct);
  function CardProductWithFunc(props) {
    return (
      <CardProduct
        {...props}
        onClick={templateData => window.open(templateData.actionUrl)}
      />
    );
  }
  CardMap.set(CardTypeCode.CardProduct, CardProductWithFunc);

  const CardVoucher = CardMap.get(CardTypeCode.CardVoucher);
  function generateVoucherUrl(content) {
    return `${getGovHost()}/shop/i/landing_page/voucher?sellerId=${content.sellerId}&voucherId=${
      content.voucherId
    }&wh_weex=true&scene=im&domain=store`;
  }
  function CardVoucherWithFunc(props) {
    return (
      <CardVoucher
        {...props}
        onClick={data => window.open(data.pcBuyerUrl || generateVoucherUrl(data))}
      />
    );
  }
  CardMap.set(CardTypeCode.CardVoucher, CardVoucherWithFunc);

  function getOrderUrl(content) {
    const orderToken = content.title.match(/#(\d+)/);
    const orderId = content.orderId || (orderToken && orderToken[1] ? orderToken[1] : '');
    return `https://my.${getSiteMainDomain()}/customer/order/view/?tradeOrderId=${orderId}`;
  }
  const CardOrder = CardMap.get(CardTypeCode.CardOrder);
  function CardOrderWithFunc(props) {
    return (
      <CardOrder
        {...props}
        onClick={data => window.open(data.pcBuyerUrl || getOrderUrl(data))}
      />
    );
  }
  CardMap.set(CardTypeCode.CardOrder, CardOrderWithFunc);

  const CardImage = CardMap.get(CardTypeCode.CardImage);
  function CardImageWithFunc(props) {
    return (
      <CardImage
        {...props}
        onClick={data => setImagePreviewData(data.imgUrl, { hideSubmit: true })}
      />
    );
  }
  CardMap.set(CardTypeCode.CardImage, CardImageWithFunc);

  const CardEmoji = CardMap.get(CardTypeCode.CardEmoji);
  const emojiMap = {};
  emojiGroup.forEach((group) => {
    group.list.forEach((item) => {
      emojiMap[item.key] = item;
    });
  });
  function CardEmojiWithFunc(props) {
    return <CardEmoji {...props} emojiMap={emojiMap} />;
  }
  CardMap.set(CardTypeCode.CardEmoji, CardEmojiWithFunc);

  const CardAction = CardMap.get(CardTypeCode.CardAction);
  @inject(({ Store }) => ({
    activeSession: Store.activeSession,
  }))
  class CardActionWithFunc extends React.Component {
    render() {
      const { activeSession, ...rest } = this.props;
      return (
        <CardAction {...rest} onClick={action => sendChoice(action, activeSession)} />
      );
    }
  }
  CardMap.set(CardTypeCode.CardAction, CardActionWithFunc);

  updateUnpaidOrderCard(CardMap);
  updateTextCard(CardMap);
}

export { enhanceCard };

const mapProps = ({ Store }) => ({
  isLoading: Store.isMoreMessageLoading,
  hasMore: true,
  loadMore: Store.getMoreMessage,
  messageList: Store.messageList,
  langKey: Store.sdkConfig.lang,
  onDelete: Store.selectRemoveMessage,
  onResend: Store.resendMessage,
  sessionId: Store.activeSessionId,
  symbolReplace: createSymbolReplace(Store.emojiGroup),
  messageWithdraw: Store.messageWithdraw,
  reUpdateInputMessage: Store.reUpdateInputMessage,
});

export default inject(mapProps)(MessageList);
