import requestMtop from '../utils/mtop';

function sendChoice(action, activeSession) {
  const targetId = activeSession.toUser && activeSession.toUser.userId;
  return requestMtop({
    api: 'mtop.im.lzd.imcenter.app.buyer.action',
    data: {
      loginAccountType: 1,
      targetAccountType: 2,
      targetAccountId: targetId,
      actionCode: action.code,
    },
  });
}

export {
  sendChoice,
};
