// import _default from '@ali/gcom-lzd-mtop';

function getApiRequester() {
  const chat = window._chat;
  return chat?.instance?.im?._api;
}

function getAccessData() {
  const chat = window._chat;
  const config = chat?.instance?.im?.config;
  if (!config) {
    return {};
  }
  const { accessKey, accessToken } = config;
  return { accessKey, accessToken };
}

function requestMtop({ api, data, opt = {} }) {
  const req = getApiRequester();
  const base = getAccessData();
  
  return req.request(api, { ...base, ...data }, opt);
}

export default requestMtop;
