import React from 'react';
import { observer, inject } from 'mobx-react';
import ComPure from '../../components/window-confirm/index';

const Component = inject('Store')(observer(({ Store }) => (
  <ComPure
    sendData={Store.windowConfirmData}
    title={Store.windowConfirmData.title}
    onCancel={() => {
      Store.unSelectRemoveSession();
    }}
    onOk={(session) => {
      Store.deleteSession(session.id);
    }}
  />
)));

Component.displayName = 'WindowConfirm';

export default Component;
