import get from 'lodash.get';

function getSite() {
  const host = window.location.host;
  if (/\.lazada\./.test(host)) {
    return 'lazada';
  } else if (/\.daraz\./.test(host)) {
    return 'daraz';
  } else if (/\.shop\./.test(host)) {
    return 'shop';
  } else {
    throw new Error('unsupported site');
  }
}

const siteName = getSite();

const getRegion = () => get(window, '_imSDKconfig.region', 'sg');

const getSuffix = () => {
  const segs = window.location.host.split(siteName);
  const last = segs[segs.length - 1];
  return last.substring(1);
};

const getSiteMainDomain = () => `${siteName}.${getSuffix()}`;

export { siteName, getSuffix, getRegion, getSiteMainDomain };

const getGovHost = () => `https://www.${getSiteMainDomain()}`;

export default getGovHost;
