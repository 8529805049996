import '@ali/lib-mtop';
import libAccs from '@ali/lib-accs';

// 提前挂在accs 目前在 im-core文件里引入 没效果
if (!window?.lib?.accs) {
    if (!window?.lib) {
      window.lib = {};
    }
    window.lib.accs = libAccs;
  }
  
  export const checkLibAccs = () => {
    if (!window?.lib?.accs) {
      if (!window?.lib) {
        window.lib = {};
      }
      window.lib.accs = libAccs;
    }
  };