import classnames from 'classnames';
import { reaction } from 'mobx';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.scss';

@inject('Store')
class FieldsUpload extends Component {
  static propTypes = {
    onFilesSelect: PropTypes.func.isRequired,
    trigger: PropTypes.node,
    className: PropTypes.string,
    accept: PropTypes.string,
    Store: PropTypes.shape({
      imagePreviewData: PropTypes.object,
    }).isRequired,
  };

  static defaultProps = {
    trigger: null,
    className: null,
    accept: null,
  };

  constructor(props) {
    super(props);

    reaction(
      () => props.Store.imagePreviewData,
      () => {
        if (props.Store.imagePreviewData === null) {
          this.field.value = null;
        }
      }
    );
  }

  onChange = (e) => {
    const { onFilesSelect } = this.props;
    const { files } = e.target;

    onFilesSelect && onFilesSelect(files);
  };

  setRef = (ref) => {
    this.field = ref;
  };

  render() {
    const { trigger, className, accept } = this.props;

    return (
      <label className={classnames('file-upload', className)}>
        {trigger}
        <input
          className="file-upload__control"
          style={{ width: 0, overflow: 'hidden' }}
          ref={this.setRef}
          type="file"
          onChange={this.onChange}
          accept={accept}
        />
      </label>
    );
  }
}

export default FieldsUpload;
