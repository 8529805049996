const XHRState = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4,
};

function formfyData(data) {
  const formData = new FormData();
  const keys = Object.getOwnPropertyNames(data);
  keys.forEach(key => {
    const value = data[key];
    formData.append(key, value);
  });
  return formData;
}

function processOption(options) {
  if (!options) {
    return {};
  }
  const finalOptions = { ...options };
  if (options.requestType === 'form') {
    finalOptions.data = formfyData(options.data);
  }
  return finalOptions;
}

function requestAjax(method, url, options) {
  options = processOption(options);
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === XHRState.DONE && req.status === 200) {
        resolve(JSON.parse(req.responseText));
      }
    };
    req.onerror = reject;
    req.open(method, url);
    req.send(options.data);
  });
}

export default requestAjax;
