import React from 'react';
import PropTypes from 'prop-types';
import Message from '../message';
import './index.scss';

const SkeletonMessagesList = ({ items }) => (
  <div className="skeleton-messages-list">
    {Array(items)
      .fill()
      .map((_, index) => index)
      .map(item => <Message key={item} type={item % 2 === 0 ? 'buyer' : 'seller'} />)}
  </div>
);

SkeletonMessagesList.propTypes = {
  items: PropTypes.number,
};

SkeletonMessagesList.defaultProps = {
  items: 7,
};

export default SkeletonMessagesList;
